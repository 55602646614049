"use strict";

angular.module("informaApp")
    .service("ChartService", ["BaseApiService", "ConstantsSvc", 'FileDownloader', 'OmnitureSvc', function (BaseApiService, ConstantsSvc, FileDownloader, OmnitureSvc) {
        function exportChart(url, searchData) {
            var options = {
                url: ConstantsSvc.API.URL + url,
                method: 'POST',
                data: {
                    searchData
                }
            };

            const filename = 'PremiaTimeline-Export.xlsx';

            OmnitureSvc.export.trackChart(filename);
            return FileDownloader.downloadBinaryFile(options, filename, function () {
                console.log(`Excel download complete`);
            });
            
            // return FileDownloader.downloadBinaryFile(options, filename);
        }

        return {
            trendView: {
                byDisease: function (params) {
                    return BaseApiService.post('charts/yearcomparison/disease', params);
                    // return $http.post(ConstantsSvc.API.URL + "charts/yearcomparison/diseases", params);
                },
                bySubDisease: function (params) {
                    return BaseApiService.post('charts/yearcomparison/subdiseases', params);
                    // return $http.post(ConstantsSvc.API.URL + "charts/yearcomparison/subdiseases", params);
                },
                byIndications: function (params) {
                    return BaseApiService.post('charts/yearcomparison/indications', params);
                    // return $http.post(ConstantsSvc.API.URL + "charts/yearcomparison/indications", params);
                },
                byCompanies: function (params) {
                    return BaseApiService.post('charts/yearcomparison/companies', params);
                    // return $http.post(ConstantsSvc.API.URL + "charts/yearcomparison/companies", params);
                },
                byDrugClasses: function (params) {
                    return BaseApiService.post('charts/yearcomparison/drugclasses', params);
                    // return $http.post(ConstantsSvc.API.URL + "charts/yearcomparison/drugclasses", params);
                },
                byMolecule: function (params) {
                    return BaseApiService.post('charts/yearcomparison/molecules', params);
                    // return $http.post(ConstantsSvc.API.URL + "charts/yearcomparison/molecules", params);
                },
                byTarget: function (params) {
                    return BaseApiService.post('charts/yearcomparison/targets', params);
                    // return $http.post(ConstantsSvc.API.URL + "charts/yearcomparison/targets", params);
                },
                byDrug: function (params) {
                    return BaseApiService.post('charts/yearcomparison/drugs', params);
                    // return $http.post(ConstantsSvc.API.URL + "charts/yearcomparison/drugs", params);
                },
                byRoute: function (params) {
                    return BaseApiService.post('charts/yearcomparison/routes', params);
                    // return $http.post(ConstantsSvc.API.URL + "charts/yearcomparison/routes", params);
                },
                allData: function (params) {
                    return BaseApiService.post('charts/yearcomparison/all', params);
                    // return $http.post(ConstantsSvc.API.URL + "charts/yearcomparison/all", params);
                },
            },
            ganttChart: function (drugIndicationsIds, startDate, endDate) {
                return BaseApiService.post('charts/gantt', {
                    ids: drugIndicationsIds,
                    startDate: startDate,
                    endDate: endDate
                });
                /* return $http.post(ConstantsSvc.API.URL + "charts/gantt", {
                    ids: drugIndicationsIds,
                    startDate: startDate,
                    endDate: endDate
                });
                */
            },
            exportGanttChart: function (searchData) {
                return exportChart('charts/gantt/export', searchData);
            },
            exportDurationChart: function (searchData) {
                const isDurationChart = true;

                return exportChart('charts/duration/export', searchData);
            },
        };
    }]);